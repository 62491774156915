import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js';

export const Cart = ({carttwo,setCarttwo}) => {
const [price,setPrice]=useState(0)

//count increase
useEffect(()=>{
    let initialamountwithouterror=0
    carttwo.map((pricecalculate)=>initialamountwithouterror=pricecalculate.count
    *pricecalculate.price+initialamountwithouterror)
    setPrice(initialamountwithouterror)
})

const findingposition=(particularproducts,numbers)=>{
   let totalproductposition=carttwo.indexOf(particularproducts)
   carttwo[totalproductposition].count=carttwo[totalproductposition].count+numbers
   setCarttwo([...carttwo])
}
//remove button
const removeprogram=(particularproducts)=>{
 let removedata=carttwo.filter((all)=>all.id !== particularproducts.id)
 setCarttwo(removedata);
 alert(particularproducts.productname+"has been removed")
}

//payment
async function payment(){
  alert("Move to payment");
   const stripe=await loadStripe('pk_test_51P1jwmSC1aZunkvPShJjXUjKyxifKRx4kdBLonFTxTPakhsjpSyUd6Rtxqm3b4XnflxhoeiP3qwgjcNMBiTNsODP003iY4q56L')

   const productpassing={
    products:carttwo
   }
   const headers ={
    "Content-Type":"application/json"
   }
   const response = await fetch("http://localhost:3200/api/create-checkout-session",{
    method:"POST",
    headers:headers,
    body:JSON.stringify(productpassing)
   })
   const session = await response.json();

   const result = stripe.redirectToCheckout({
    sessionId:session.id
});
if (result.error) {
  console.log(result.error);
}
}

    return (
    <div>
     {/* fontawesome cdn */}
     <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
     <div className='newheaderContainer'>
    <h1><i class="fa-solid fa-gem"></i> TreasureTrova</h1>  
    <div className='newheaderContainerLinks'>     
    <Link to='/home' className='link-styless' id='linking'>Home</Link>
    <Link to='/product' className='link-styless' id='linking'>Products</Link>
    <Link to='/login' className='link-styless' id='linking'>Login</Link>
    <Link to='/signup' className='link-styless' id='linking'>SignUp</Link>
    <Link to='/cart' className='link-stylessss' id='blockling'><i class="fa-solid fa-cart-shopping"></i></Link> 
    </div>
    </div>
    <header className='headerImg'>
    <div className='headerImg1'></div>
    <div className='headerImg2'>
    <h2>Welcome To</h2>
         <h3>TreasureTrova</h3>
         <p>Discover the hidden gems of the shopping world at TreasureTrova, where every click unveils a treasure trove of unique finds.Let your shopping experience be an adventure with TreasureTrova. </p>
         <button><a href='#cartproductSection' className='link-stylesss'>View Cart</a></button>
    </div>
    </header>

    <main className='CartMainSection'>
    <section id='cartproductSection'>
      <h1>Cart</h1>
    <div className='CartAddedProducts'>
        {
            carttwo.map((product)=>
            <div className='EachCartAddedProducts'>
              <div className='EachCartAddedProductsimage'>
              <img src={product.image}/>
               
              </div>
              <div className='EachCartAddedProductsdetails'>
              <h1>{product.productname}</h1>
               <h2>{product.brand}</h2>
              <h3><i class="fa-solid fa-indian-rupee-sign"></i>  {product.price}</h3>
               <p>{product.description}</p>
               <h4><i class="fa-solid fa-star"></i>{product.rating}</h4>
               <div className='IncrementAndDecrement'>
               <button className='Increments' onClick={()=>findingposition(product,1)}>+</button>
               <h6 className='NumberOfCounts'>{product.count}</h6>
               <button className='Decrements' onClick={()=>findingposition(product,-1)}>-</button>
               </div>
               <button className='CartRemovebutton' onClick={()=>removeprogram(product)}>Remove</button>
              </div>
              
            </div>
            )
        }
    </div>
        <h1 className='TotalcartAmount'>Total Cart Amount <i class="fa-solid fa-indian-rupee-sign"></i> {price}</h1>
        <button className='PaymentButton' onClick={payment}>Payment</button>
      </section>
      </main>
    </div>
  )
}

export default Cart