import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export const Login = () => {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    
    const passing=useNavigate()
    async function signupcode(e){
        e.preventDefault();
       await axios.post("http://localhost:4300/login",{email,password}).then((res)=>{
            if(res.data=="exist"){
               passing('/home')
            }else{
                alert("Please check your Email Id and Password")
            }
        })
    }
  return (
    <div className='LoginPage'>
     {/* fontawesome cdn */}
     <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />

    <div className='LoginContainer'>
    <h1>Login <i class="fa-regular fa-face-laugh-wink"></i></h1>
    <form method='post' onSubmit={signupcode} className='LoginForm'>
          <input type='email' placeholder='Enter Your Email' onChange={(event)=>setEmail(event.target.value)} required className='LoginEmail'></input>
          <br></br>
          <input type='password' placeholder='Enter Your Password' onChange={(event)=>setPassword(event.target.value)} required className='LoginPassword'></input>
          <br></br>
          <button type='submit' className='LoginSubmit'>Submit</button>
    </form>
    <p className='alreadyAccount'>If You Didn't Have Account First  <Link to='/signup'>SignUp</Link></p>
   
    </div>
    
    </div>
  )
}

export default Login