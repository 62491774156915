import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'


export const Signup = () => {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')

    const passing=useNavigate()
   async function signupcode(e){
        e.preventDefault();
       await axios.post("http://localhost:4300/signup",{email,password}).then((res)=>{
            if(res.data=="exist"){
               alert("Please change your Email Id and Password")
            }else{
                passing('/login')
            }
        })
    }
  return (
    <div className='signuppage'>
    {/* fontawesome cdn */}
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
   
    <div className='signupContainer'>
    <h1>SignUp <i class="fa-solid fa-face-grin-wink"></i></h1>
    <form method='post' onSubmit={signupcode} className='signupForm'>
    <input type='email' placeholder='Enter Your Email' onChange={(event)=>setEmail(event.target.value)} required className='signupEmail'></input>
    <br></br>
    <input type='password' placeholder='Enter Your Password' onChange={(event)=>setPassword(event.target.value)} required className='signupPassword'></input>
        <br></br>  
    <button type='submit' className='signupSubmit'>Submit</button>
    </form>
    <p className='alreadyAccount'>If You Already have a account Just <Link to='/login' className='link-styles'>Login</Link></p>
    
    </div>
    
    </div>
  )
}

export default Signup