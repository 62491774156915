import React from 'react'
import { Link } from 'react-router-dom'

const Newhome = () => {

  return (
    <div>
    {/* fontawesome cdn */}
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    
    <div className='newheaderContainer'>
    <h1><i class="fa-solid fa-gem"></i> TreasureTrova</h1>  
    <div className='newheaderContainerLinks'>     
    <Link to='/home' className='link-styless' id='linking'>Home</Link>
    <Link to='/product' className='link-styless' id='linking' >Product</Link>
    <Link to='/login' className='link-styless' id='linking'>Login</Link>
    <Link to='/signup' className='link-styless' id='linking'>SignUp</Link>
    <Link to='/cart' className='link-stylessss' id='blockling'><i class="fa-solid fa-cart-shopping"></i></Link> 
     
    </div>
    </div>
    <header className='headerImg'>
    <div className='headerImg1'></div>
    <div className='headerImg2'>
    <h2>Welcome To</h2>
         <h3>TreasureTrova</h3>
         <p>Discover the hidden gems of the shopping world at TreasureTrova, where every click unveils a treasure trove of unique finds.Let your shopping experience be an adventure with TreasureTrova. </p>
         <button><Link to='/product'  className='link-stylesss'>Shop Now</Link></button>
    </div>
    </header>
    </div>
  )
}

export default Newhome