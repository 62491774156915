import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import Login from './components/Login';
import Signup from './components/Signup';
import Newhome from './Newhome';
import Productsshow from './Productsshow';
import Cart from './Cart';
import { useState } from 'react';
import { Success } from './Success';
import { Failure } from './Failure';

function App() {
   const [cart,setCart]=useState([])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage></Homepage>}></Route>
          <Route path='/login' element={<Login></Login>}></Route>
          <Route path='/signup' element={<Signup></Signup>}></Route>
          <Route path='/home' element={<Newhome></Newhome>}></Route>
          <Route path='/product' element={<Productsshow   cartone={cart} setCartone={setCart}></Productsshow>}></Route>
          <Route path='/cart' element={<Cart carttwo={cart} setCarttwo={setCart}></Cart>}></Route>
          <Route path='/sucess' element={<Success></Success>}></Route>
          <Route path='/cancel' element={<Failure></Failure>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
