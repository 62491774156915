import React from 'react'
import { Link } from 'react-router-dom'

export const Homepage = () => {
  return (
    <div className='homePage'>
    
    <div className='container'>  
    <h1>TreasureTrova</h1>
    <p>Discover the hidden gems of the shopping world at TreasureTrova, where every click unveils a treasure trove of unique finds.Let your shopping experience be an adventure with TreasureTrova.</p>
    
    <div className='buttons'>
    <button className='button1'><Link to='/login' className='link-style'>Login</Link></button>  
    <button className='button2'><Link to='/signup' className='link-style'>SignUp</Link> </button>
    </div>
    
    </div>
    
    </div>
    
  )
}

export default Homepage