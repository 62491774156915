const Productdetails=[
    {
        id:1,
        productname:"I-Phone-x",
        brand:"Apple",
        price:15000,
        count:1,
        description:" SIM-Free, Model A19211 6.5-inch Super Retina HD display with OLED technology A12 Bionic chip with ...",
        image:require("./images/ID11.png"),
        rating:4.44
      },
    {
      id:2,
      productname:"I-Phone 9",
      brand:"Apple",
      price:20000,
      count:1,
      description:"An apple mobile which is nothing like apple",
      image:require("./images/ID12.png"),
      rating:4.69
    },
    {
        id:3,
        productname:"Samsung Universe-9",
        price:23000,
        brand:"Apple",
        count:1,
        description:"Samsung's new variant which goes beyond Galaxy to the Universe",
        image:require("./images/ID13.png"),
        rating:4.09
    },
    {
        id:4,
        brand:"OPPO",
        productname:"OPPO F19",
        price:14000,
        count:1,
        description:"OPPO F19 is officially announced on April 2021 in leading segment",
        image:require("./images/ID14.png"),
        rating:4.3
    },
    {
        id:5,
        brand:"Apple",
        productname:"MacBook Pro",
        price:160000,
        count:1,
        description:"MacBook Pro 2021 with mini-LED display may launch between September",
        image:require("./images/ID15.png"),
        rating:4.57
    },
    {
        id:6,
        brand:"Infinix",
        productname:"Infinix 6",
        price:12000,
        count:1,
        description:"Infinix 6 best build quality in the segment, Grey – 1 Year Warranty",
        image:require("./images/ID16.png"),
        rating:4.54
    },
    {
        id:7,
        brand:"HP Pavilion",
        productname:"HP Pavilion 15-DK1056WM",
        price:70000,
        count:1,
        description:"HP Pavilion 15-DK1056WM Gaming Laptop 10th Gen Core i5, 8GB, 256GB SSD, GTX 1650 4GB, Windows 10",
        image:require("./images/ID17.png"),
        rating:4.43
    },
    {
        id:8,
        brand:"Apple",
        productname:"Apple 15 pro",
        price:12000,
        count:1,
        description:"A camera that captures your wildest imagination. From dramatic framing flexibility to next-generation portraits, see what you can do with our most powerful",
        image:require("./images/ID18.png"),
        rating:4.70
    },
    {
        id:9,
        brand:"Apple",
        productname:"Airpods Pro",
        price:30000,
        count:1,
        description:"AirPods deliver an unparalleled wireless headphone experience, from magical setup to high-quality sound.",
        image:require("./images/ID19.png"),
        rating:4.0
    },

]


export default Productdetails