import React from 'react'
import { Link } from 'react-router-dom'
import Productdetails from './Productdetails'

export const Productsshow = ({cartone,setCartone}) => {
  return (
    <div>
    {/* fontawesome cdn */}
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div className='newheaderContainer'>
    <h1><i class="fa-solid fa-gem"></i> TreasureTrova</h1>  
    <div className='newheaderContainerLinks'>     
    <Link to='/home' className='link-styless' id='linking'>Home</Link>
    <a href='#productSection' className='link-styless' id='linking'>Product</a>
    <Link to='/login' className='link-styless' id='linking'>Login</Link>
    <Link to='/signup' className='link-styless' id='linking'>SignUp</Link>
    <Link to='/cart' className='link-stylessss' id='blockling'><i class="fa-solid fa-cart-shopping"></i></Link> 
    </div>
    </div>
    <header className='headerImg'>
    <div className='headerImg1'></div>
    <div className='headerImg2'>
    <h2>Welcome To</h2>
         <h3>TreasureTrova</h3>
         <p>Discover the hidden gems of the shopping world at TreasureTrova, where every click unveils a treasure trove of unique finds.Let your shopping experience be an adventure with TreasureTrova. </p>
         <button><a href='#productSection' className='link-stylesss'>View Products</a></button>
    </div>
    </header>
    <main className='MainSection'>
    <section id='productSection'>
        <h1>Products</h1>
{/* Get data from products details */}
        <div className='AvailableProducts'>
        {   
            Productdetails.map((product)=>
            <div className='EachProducts'>
              <img src={product.image}></img>
               <h1>{product.productname}</h1>
               <h2>{product.brand}</h2>
                <h3><i class="fa-solid fa-indian-rupee-sign"></i>  {product.price}</h3>
               <p>{product.description}</p>
               <h4><i class="fa-solid fa-star"></i>{product.rating}</h4>
               <button onClick={()=>{setCartone([...cartone,product]);alert(product.productname+" Added Successfully")}}>Add to cart</button>
            </div>
            )
        }
    </div>
    </section>
    </main>
    </div>
  )
}

export default Productsshow